import axios from "axios";
import API_URL from '../../http/index';
const REACT_APP_API_URL = window.location.hostname == 'localhost' ? `http://localhost:5002` : `https://${window.location.hostname}`;
// const REACT_APP_API_URL = `http://localhost:5002/`;
const $host = axios.create({
    baseURL: REACT_APP_API_URL
});

export {
    $host
}