import React, {useContext, useEffect} from 'react';
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import logo from "../../images/logo.png";

const MobNav = ({handleToggleMobNav}) => {
    const {storeblog} = useContext(Context)
    const categoriesNames = ['Finance', 'Zdraví', 'Další']
    const categories = ['FINANCE', 'HEALTH', 'ANOTHER'];

    useEffect(()=>{
        console.log(storeblog.selectedCategory , 'storeblog.selectedCategory')
    },[storeblog.selectedCategory])

    return (
        <div className='main-mob-menu'>

                            <ul className="main-mob-menu_list">
                                {categories.map((e,i) =>
                                    <li className="nav-item" key={i}>
                                        <Link to={`category/${categories[i]}`}
                                              onClick={() => {
                                                  storeblog.setSelectedCategory(e)
                                                  handleToggleMobNav()
                                                  console.log(e)
                                              }}
                                              className='nav-link cursor-pointer text-decoration-none'>
                                            {categoriesNames[i]}
                                        </Link>
                                    </li>
                                )}
                            </ul>

        </div>
    );
};

export default observer(MobNav);